import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from 'src/context/authContext';
import { ToastProvider } from 'src/context/toastContext';
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login'))

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <ToastProvider>
          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Routes>
            </Suspense>
          </HashRouter>
        </ToastProvider>
      </AuthProvider>

    )
  }
}

export default App
