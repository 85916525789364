import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authDetails, setAuthDetails] = useState(() => {
    // Load the auth details from local storage (if present)
    const storedDetails = localStorage.getItem('authDetails');
    return storedDetails ? JSON.parse(storedDetails) : { accessToken: null, refreshToken: null };
  });

  // Update local storage whenever the authDetails change
  useEffect(() => {
    localStorage.setItem('authDetails', JSON.stringify(authDetails));
  }, [authDetails]);

  // Mock login function (replace with actual login logic)
  const login = async (email, password) => {
    try {
      const response = await fetch(`https://app-api-we7tf52pmq-uc.a.run.app/users/login/admin?email=${email}&password=${password}`, {
        method: 'GET', // Make sure to use the correct method as per your API
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();

      setAuthDetails({
        accessToken: data.token,
        refreshToken: data.refreshToken,
      });

      return data; // Return the response data
    } catch (error) {
      console.error('Login error:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  const logout = () => {
    // Clear the auth details and local storage
    setAuthDetails({
      accessToken: null,
      refreshToken: null,
    });
    localStorage.removeItem('authDetails');
  };

  // Function to refresh the access token using the refresh token
  const refreshAccessToken = async () => {
    try {
      const response = await fetch(`http://localhost:8080/users/refresh?authenticationToken=${encodeURIComponent(authDetails.accessToken)}&refreshToken=${encodeURIComponent(authDetails.refreshToken)}`, {
        method: 'GET', // Make sure to use the correct method as per your API
      });

      if (!response.ok) {
        throw new Error('Token refresh failed');
      }

      const data = await response.json();
      setAuthDetails(prevDetails => ({
        ...prevDetails,
        accessToken: data.accessToken, // Replace with actual field names from your API response
      }));
    } catch (error) {
      console.error('Refresh token error:', error);
      // Handle refresh error (e.g., invalid token, network error)
    }
  };

  // Set up automatic token refresh here...
  useEffect(() => {
    let interval;
    if (authDetails.refreshToken) {
      interval = setInterval(() => {
        refreshAccessToken();
      }, 1000 * 60 * 14); // Refresh the token every 14 minutes, for example
    }

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [authDetails.refreshToken, refreshAccessToken]);

  const contextValue = {
    authDetails,
    setAuthDetails,
    login,
    logout,
    refreshAccessToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
