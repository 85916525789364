import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const handleSuccessToast = (message) => {
        toast.success(message);
    };

    const handleWarningToast = (message) => {
        toast.warn(message);
    };

    const handleErrorToast = (message) => {
        toast.error(message);
    };

    return (
        <ToastContext.Provider value={{ handleSuccessToast, handleWarningToast, handleErrorToast }}>
            {children}
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
        </ToastContext.Provider>
    );
};

ToastProvider.propTypes = {
    children: PropTypes.node
};

// Usage of the ToastComponent might not be necessary unless you have specific custom styling or behavior.
// The ToastContainer imported from 'react-toastify' is being used to display the toasts configured in the handlers.
